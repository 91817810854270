Iframe {
	width: 100%;
	height: 100%;
	border: none;
}

h2 {
  margin: 0;
}

.iframe-header {
  position: absolute;
  bottom: 0;
  left: 0;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
	color: white;
	background-color: none;
  flex-direction: row;
  padding: 1rem 1rem;
  border-top-right-radius: 10px;
}

.iframe-user-email {
  color: #FFF;
}

.iframe-user-info-sub-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.iframe-user-info {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.iframe-logout-button {
  text-align: center;
  cursor: pointer;
	background-color: #FFF;
	color: #000;
  width: 30%;
  font-weight: 600;
  border-radius: 5px;
  padding: 5px 10px;
}

.iframe-buttons-sub-container {
  flex: 1;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.iframe-title-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 5px;
}

.iframe-title-big-name {
  font-size: 20px;
  font-weight: 200;
  color: #FFF;
}

.iframe-loading-container {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
}

.iframe-title-big-bar {
  margin: 0px 10px;
  font-size: 20px;
  font-weight: 200;
  color: #FFF;
}
