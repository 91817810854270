p {
	padding: 0px;
	margin: 0px;
}

.select-screen {
  font: 'Roboto', sans-serif;
  background-color: #6400AA;
	display: flex;
	flex-direction: column;
  align-items: center;
  justify-content: center;
	height: 100vh;
  text-align: center;
  font-size: calc(5px + 2vmin);
}

.select-box {
  color: #6400AA;
  position: relative;
	background-color: #FFF;
  width: 80%;
	max-width: 400px;
	padding: 20px 40px;
  padding-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
}

.select-title-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 40px;
}

.select-title-big-bar {
  margin: 0px 20px;
  font-size: 40px;
  height: 40px;
  font-weight: 200;
  color: #6400AA;
}

.select-title-big-name {
  font-size: 40px;
  height: 40px;
  font-weight: 200;
  color: #6400AA;
}

.select-input-form {
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.select-user-input-field {
	width: 100%;
	display: flex;
	justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0px;
}

.select-user-input-field-input {
  flex-grow: 1;
  font: 'Roboto', sans-serif;
  margin-left: 10px;
  font-size: 20px;
  padding: 10px 10px;
  border: none;
  border-bottom: 2px solid #6400AA;
}

.selection-option {
  font-size: 20px;
  padding: 10px 10px;
  margin: 5px 0px;
  background-color: #FF5F00;
  color: #FFF;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
  width: 100%;
}

.select-user-input-field-input:focus {
  outline: none;
}

.select-submit-butotn-container {
	width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select-login-button {
	background-color: #FF5F00;
	color: #FFF;
  font: 'Roboto', sans-serif;
	font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  outline: inherit;
  border: none;
  padding: 10px 20px;
  box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.35);
}
